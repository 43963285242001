import _helper from "../helper";
import _types from "../types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.app = exports.Action = void 0;
var helper_1 = _helper;
var types_1 = _types;
var Action;
(function (Action) {
  Action["APP"] = "APP::PRINT::APP";
})(Action = exports.Action || (exports.Action = {}));
function app() {
  return helper_1.actionWrapper({
    group: types_1.Group.Print,
    type: Action.APP
  });
}
exports.app = app;
export default exports;
export const __esModule = exports.__esModule;
const _app = exports.app,
  _Action = exports.Action;
export { _app as app, _Action as Action };